import React from 'react';
import { BlogPostVideo } from './StyledComponent';

const VideoPlayer = ({ videoFrame }) => {
  return (
    <BlogPostVideo dangerouslySetInnerHTML={{ __html: videoFrame }}>
    </BlogPostVideo>
  )
}

export default VideoPlayer;
