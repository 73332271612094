import React from "react";

/** Importing styled components */
import {
  StyledCarouselPost,
  StyledCarouselSlidePost,
  FluidImage
} from "./StyledComponent";
/** Importing styles for react Slick */
import "./../styles/slider.css";
import VideoPlayer from "./VideoPlayer";

const CarouselPost = ({ slidesData, height }) => {
  const settings = {
    accesibility: true,
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000
  };
  return (
    <>
      <StyledCarouselPost
        styles={slidesData.iframeVideo.length === 0 || slidesData.cover === "" ? {padding: "4rem 1rem 0"} : null}
        height={height}
        {...settings}
        autoplay={slidesData.iframeVideo ? false : true}
      >
        {slidesData.iframeVideo ? (
          <VideoPlayer videoFrame={slidesData.iframeVideo}></VideoPlayer>
        ) : (
          null
        )}
        {slidesData.cover
          ? slidesData.cover.map((el, i) => {
              return (
                <StyledCarouselSlidePost key={i}>
                  <FluidImage
                    fluid={el.image.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt=""
                  />
                </StyledCarouselSlidePost>
              );
            })
          : null}
      </StyledCarouselPost>
    </>
  );
};

export default CarouselPost;
